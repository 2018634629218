
import config from "../config/config.json";

export const environment = {
  production: config.production,

  strapi_server: config.strapi_server,
  strapi_file_library: config.strapi_file_library,
  twoFactorAuth_server:config.twoFactorAuth_server,
  report_server:config.report_server,
  instance_name: config.instance_name,

  s3_bucket:config.s3_bucket,
  available_modules: config.avaliable_modules
};